var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('AuthHeader'),_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Forgot password V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('Forgot Password'))+" 🔒 ")]),(!_vm.nextStep)?_c('validation-observer',{ref:"sendEmailForm"},[_c('b-form',{staticClass:"auth-forgot-password-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.sendEmailForm.apply(null, arguments)}}},[_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('labels.email_phone'),"vid":"value_email_or_mobile","rules":"required|custom_validate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.email_phone')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('labels.email_phone')},model:{value:(_vm.value_email_or_mobile),callback:function ($$v) {_vm.value_email_or_mobile=$$v},expression:"value_email_or_mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])],1)]}}],null,false,804174334)})],1),_c('b-button',{attrs:{"disabled":_vm.loading,"type":"submit","variant":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.$t('Restore password'))+" ")])],1)],1):_vm._e(),(_vm.nextStep && !_vm.finalStep)?_c('validation-observer',{ref:"verifyCodeForm"},[_c('b-form',{staticClass:"auth-forgot-password-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.verifyCodeForm.apply(null, arguments)}}},[_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('labels.code'),"vid":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.please_enter_code')}},[_c('v-otp-input',{ref:"otpInput",staticClass:"otp-input-outer my-2",attrs:{"input-classes":"otp-input","separator":"-","num-inputs":4,"should-auto-focus":true,"is-input-num":true,"value":''},on:{"on-change":_vm.handleOnChange,"on-complete":_vm.handleOnComplete}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1253295250)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.$t('labels.submit'))+" ")])],1)],1):_vm._e(),_c('validation-observer',{directives:[{name:"show",rawName:"v-show",value:(_vm.finalStep),expression:"finalStep"}],ref:"resetPasswordObserver"},[_c('b-form',{ref:"resetPasswordForm",staticClass:"auth-forgot-password-form mt-2",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.resetPasswordForm.apply(null, arguments)}}},[_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('labels.password'),"vid":"new_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.password')}},[_c('b-form-input',{attrs:{"type":"password","placeholder":_vm.$t('labels.password'),"autocomplete":"new-password"},model:{value:(_vm.form.new_password),callback:function ($$v) {_vm.$set(_vm.form, "new_password", $$v)},expression:"form.new_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('labels.password_confirmation'),"vid":"new_password_confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.password_confirmation')}},[_c('b-form-input',{attrs:{"type":"password","placeholder":_vm.$t('labels.password_confirmation'),"autocomplete":"new-password"},model:{value:(_vm.form.new_password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "new_password_confirmation", $$v)},expression:"form.new_password_confirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.$t('labels.submit'))+" ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_vm._v(" "+_vm._s(_vm.$t('Back to login'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }